import React from "react"
import classes from "./ServiceFooter.module.scss"
import Button from "../Button/Button"

export default ({ contactSubject }) => {
  const query = encodeURIComponent(contactSubject)

  return (
    <div className={classes.ServiceFooter}>
      <Button target={`/kapcsolat?targy=${query}`}>Írj nekünk</Button>
    </div>
  )
}
