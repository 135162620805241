import React from "react"
import HTMLContent from "../components/HTMLContent/HTMLContent"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import BodyText from "../components/BodyText/BodyText"
import Container from "../components/Container/Container"
import ServiceFooter from "../components/ServiceFooter/ServiceFooter"
import PropTypes from "prop-types"

export const ServicePageTemplate = ({ title, content, imageSrc }) => {
  return (
    <div>
      <Img fluid={imageSrc} alt={title} />
      <h1>{title}</h1>
      <Container>
        <BodyText>
          <HTMLContent content={content} />
        </BodyText>
        <ServiceFooter contactSubject={title} />
      </Container>
    </div>
  )
}

ServicePageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
}

const ServicePage = ({ data }) => {
  const { markdownRemark: page } = data
  const sources = [
    page.frontmatter.image.childImageSharp.mobile,
    {
      ...page.frontmatter.image.childImageSharp.desktop,
      media: `(min-width: 900px)`,
    },
  ]

  return (
    <ServicePageTemplate
      title={page.frontmatter.title}
      content={page.html}
      imageSrc={sources}
    />
  )
}

ServicePage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ServicePage

export const query = graphql`
  query ServicePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        image {
          childImageSharp {
            desktop: fluid(maxWidth: 2000, maxHeight: 500, cropFocus: CENTER) {
              ...GatsbyImageSharpFluid
            }
            mobile: fluid(maxWidth: 500, maxHeight: 250, cropFocus: CENTER) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
