import React from "react"
import PropTypes from "prop-types"

const HTMLContent = ({ content, className }) => {
  return (
    <div className={className} dangerouslySetInnerHTML={{ __html: content }} />
  )
}

HTMLContent.propTypes = {
  content: PropTypes.node,
  className: PropTypes.string,
}

export default HTMLContent
