import React from "react"
import classes from "./Button.module.scss"
import { Link } from "gatsby"

export default ({
  target = null,
  isSubmit = false,
  action = () => {},
  children,
}) => {
  if (target) {
    return (
      <Link className={classes.Button} to={target}>
        {children}
      </Link>
    )
  }

  return (
    <button
      className={classes.Button}
      onClick={action}
      type={isSubmit ? "submit" : false}
    >
      {children}
    </button>
  )
}
